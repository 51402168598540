@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App {
  font-family: 'Raleway', sans-serif;
  width: 100vw;
	height: 100vh;
  overflow-y: visible;
	overflow-x: hidden;
	scroll-snap-type: y mandatory;
	vertical-align: top;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #111111;
}

@media screen and (prefers-color-scheme: light) {
  .App {
    background-color: #FDFDFD;
  }
}

.App::-webkit-scrollbar {
  display: none;
}


